/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.button-1 {
  background-color: #0070ce;
}

.button-2 {
  background-color: #0068c1;
}

.button-3 {
  background-color: #0061b2;
}

.button-4 {
  background-color: #0157a2;
}

.button-upload {
  background-color: #00335a;
  color: #fff;
  border-bottom: 2px solid #ffde00;
  margin-top: 10px;
}

.button-contest {
  background-color: #00335a;
  color: #fff;
  border-bottom: 2px solid #ffde00;
  margin-top: 10px;
}

#left {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 52px;
  padding: 0;
  margin: 5em 0 1em;
}
#left ul {
  margin: 0;
  padding: 0;
}
#left li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
#left a {
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 0 3em;
  display: inline-block;
}
#left a:hover {
  background-color: #5abcff;
  color: #fff;
}

.right {
  position: absolute;
  margin: 3em 0 1em;
  background: rgba(0, 0, 0, 0.9);
  padding: 18px 3em;
  right: 0;
  bottom: 3em;
  opacity: 0.8;
}
.right p {
  color: #fff;
  padding: 0;
  margin: 0;
}
.right p small {
  color: #fff;
  padding: 0;
  margin: 0;
}
.right p small a {
  color: #49acff;
}
.right a {
  color: #fff;
}

@media (max-height: 480px) {
  #left {
    margin: 2em 0 1em;
  }
  #left a {
    height: 39px;
    line-height: 39px;
    padding: 0 18px;
  }
  .right {
    padding: 13px 18px;
  }
}
@media (max-width: 640px) {
  #left {
    margin: 2em 0 1em;
  }
  .right {
    bottom: 1em;
  }
}
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#maximage {
  z-index: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
}
#maximage.slick-initialized {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
#maximage .slide {
  width: 100vw;
  height: 100vh;
}
#maximage img {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

#next,
#previous {
  bottom: 0;
  height: 34px;
  position: absolute;
  right: 13px;
  z-index: 1000;
  line-height: 34px;
  color: #7b7b7b;
  font-size: 20px;
  cursor: pointer;
  width: 20px;
  text-align: center;
}
#next:hover,
#previous:hover {
  color: #49acff;
}

#footer {
  opacity: 0.8;
}

#previous {
  right: 35px;
}