// home
// sass-lint:disable no-color-literals no-important

// external css
@import 'slick-carousel/slick/slick';


.button-1 {
  background-color: #0070ce;
}

.button-2 {
  background-color: #0068c1;
}

.button-3 {
  background-color: #0061b2;
}

.button-4 {
  background-color: #0157a2;
}

.button-upload {
  background-color: #00335a;
  color: #fff;
  border-bottom: 2px solid #ffde00;
  margin-top: 10px;
}

.button-contest {
  background-color: #00335a;
  color: #fff;
  border-bottom: 2px solid #ffde00;
  margin-top: 10px;
}

#left {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 52px;
  padding: 0;
  margin: 5em 0 1em;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  a {
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 3em;
    display: inline-block;

    &:hover {
      background-color: #5abcff;
      color: #fff;
    }
  }
}

.right {
  position: absolute;
  margin: 3em 0 1em;
  background: rgba(0, 0, 0, .9);
  padding: 18px 3em;
  right: 0;
  bottom: 3em;
  opacity: .8;

  p {
    color: #fff;
    padding: 0;
    margin: 0;

    small {
      color: #fff;
      padding: 0;
      margin: 0;

      a {
        color: #49acff;
      }
    }
  }

  a {
    color: #fff;
  }
}

@media (max-height: 480px) {
  #left {
    margin: 2em 0 1em;

    a {
      height: 39px;
      line-height: 39px;
      padding: 0 18px;
    }
  }

  .right {
    padding: 13px 18px;
  }
}

@media (max-width: 640px) {
  #left {
    margin: 2em 0 1em;
  }

  .right {
    bottom: 1em;
  }
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#maximage {
  z-index: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;

  &.slick-initialized {
    opacity: 1;
    transition: opacity .5s ease-out;
  }

  .slide {
    width: 100vw;
    height: 100vh;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

#next,
#previous {
  bottom: 0;
  height: 34px;
  position: absolute;
  right: 13px;
  z-index: 1000;
  line-height: 34px;
  color: #7b7b7b;
  font-size: 20px;
  cursor: pointer;
  width: 20px;
  text-align: center;

  &:hover {
    color: #49acff;
  }
}

#footer {
  opacity: .8;
}

#previous {
  right: 35px;
}
